import { useState } from "react";
import axios from "axios";
import "../questionnaire/styles/index.scss";
import "./Registration.scss";
import Questionnaire from "../questionnaire/componenets/Questionnaire";
import ClosedQuestionnaire from "../questionnaire/componenets/ClosedQuestionnaire";
import Billing from "./Billing";
import Agree from "./Agree";
import ReferralLetter from "./ReferralLetter";
import { Stepper } from "../progress/stepper";
import questionListName from "../utils/constants/questionListName";
import { useQuery } from "react-query";
import { queryClient } from "../react-query/provider";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { WireUsernameQuestion } from "../questionnaire/componenets/question_views/WireUsernameQuestion";

const Welcome = () => {
  const localStorageIsOpen = localStorage.getItem("is-step-progress-open");
  const { i18n } = useTranslation();

  const getWelcomeId = () => {
    var locationEntries = window.location.pathname.split("/");
    return locationEntries[locationEntries.length - 1];
  };

  const [stage, setStage] = useState<string | undefined>(undefined);
  const [stageId, setStageId] = useState<string | undefined>(undefined);
  const [allStages, setAllStages] = useState<string[]>([]);
  const [isProgressBarOpen, setIsProgressBarOpen] = useState<boolean>(
    localStorageIsOpen == null ? true : localStorageIsOpen == "true"
  );

  const { isLoading } = useQuery(
    ["questionnaire", getWelcomeId()],
    async () => {
      const res = await axios.get(`/welcome/${getWelcomeId()}`);
      return res.data;
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data: {
        userLanguage: "nl-NL" | "en-US";
        stage: string;
        stageId: string;
        allStages: string[];
      }) => {
        setStage(data.stage);
        setStageId(data.stageId);
        setAllStages(data.allStages);

        const languageCode = data.userLanguage.slice(0, 2);

        if (localStorage.getItem("userOverridden_i18nextLng") == undefined) {
          // only override the selected language if the user didn't specify it yet him/herself (by using the language selector button)
          i18n.changeLanguage(languageCode);
        }
      },
      onError: () => {
        setStage("notFound");
      },
    }
  );

  if (isLoading) {
    return (
      <div className="questionnaire-root w-full min-h-screen flex items-center justify-center bg-blue-100 text-gray-500">
        <div className="flex flex-col items-center">
          <h1>iPractice</h1>
          <div className="flex items-center space-x-2">
            <Loader2 className="w-4 h-4 animate-spin" />
            <div>Loading...</div>
          </div>
        </div>
      </div>
    );
  }

  const refreshQuestionnaire = () => {
    queryClient.invalidateQueries(["questionnaire", getWelcomeId()]);
    queryClient.refetchQueries(["questionnaire", getWelcomeId()]);
  };

  const getBody = () => {
    let stageNameToCheck = stage;

    if (Object.values(questionListName).includes(stage!)) {
      stageNameToCheck = "questionnaire";
    }

    switch (stageNameToCheck) {
      case "iagree":
        return <Agree guid={stageId} onContinue={refreshQuestionnaire} />;
      case "billing":
        return <Billing guid={stageId} onContinue={refreshQuestionnaire} />;
      case "wireUserName":
        return (
          <WireUsernameQuestion
            patientId={stageId!}
            onSubmit={refreshQuestionnaire}
          />
        );

      case "referralLetter":
        return (
          <ReferralLetter guid={stageId} onContinue={refreshQuestionnaire} />
        );
      case "questionnaire":
        return (
          <Questionnaire
            guid={stageId}
            onContinue={refreshQuestionnaire}
            key={stageId}
            welcomeStage={stageNameToCheck}
          />
        );
      case "closed":
        return <ClosedQuestionnaire />;
      case "notFound":
        return (
          <div className="questionnaire-root registration-container">
            <div>Geen vragenlijst gevonden.</div>
          </div>
        );
      default:
        return <div>An error occured.</div>;
    }
  };

  return (
    <>
      <Stepper
        allSteps={allStages}
        currentStep={stage!}
        isOpen={isProgressBarOpen}
        setIsOpen={(newValue) => {
          setIsProgressBarOpen(newValue);
          localStorage.setItem("is-step-progress-open", newValue.toString());
        }}
      />
      <div className="my-12">{getBody()}</div>
    </>
  );
};

export default Welcome;
