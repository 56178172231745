// React
import React, { Fragment } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";

// Misc
import { Provider } from "mobx-react";
import { configure } from "mobx";
import { queryClient } from "./react-query/provider";
import { QueryClientProvider } from "react-query";

// Temporary
import errorsStore from "./questionnaire/errorsStore";
import questionnaireStore from "./questionnaire/questionnaireStore";
import RequestErrors from "./questionnaire/componenets/RequestErrors";
import Questionnaire from "./questionnaire/componenets/Questionnaire";
import iPracticeGermanyiAggree from "./iPracticeGermany/iAgree";
import CohortStudy from "./cohortStudyAgreement/cohortStudy";

// Registration
import Welcome from "./registration/Welcome";
import Agree from "./registration/Agree";
import Billing from "./registration/Billing";
import ReferralLetter from "./registration/ReferralLetter";

// Initializations
import "./axios";
import "./i18n";

configure({ enforceActions: "always" }); // https://mobx.js.org/best/actions.html

const BaseUrlFragment = () => {
  return <Fragment>Geen vragenlijst gevonden.</Fragment>;
};

const App = () => {
  return (
    <Provider errorsStore={errorsStore} questionnaireStore={questionnaireStore}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Route path="/" exact component={BaseUrlFragment} />

          <Route path="/welcome/:guid/" component={Welcome} />
          <Route path="/agree/:guid/" component={Agree} />
          <Route path="/billing/:guid" component={Billing} />
          <Route path="/referral-letter/:guid" component={ReferralLetter} />
          <Route path="/questionnaire/" exact component={Questionnaire} />
          <Route path="/questionnaire/:guid" component={Questionnaire} />
          <Route path="/de/welcome/:guid" component={iPracticeGermanyiAggree} />
          <Route path="/cohort-study/agreement/:guid" component={CohortStudy} />
          <Route path="*" component={RequestErrors} />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  );
};

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
