import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import subscriptionStatusEnum from "../utils/constants/eCurringSubscriptionStatusEnum";
import "../questionnaire/styles/index.scss";
import "./Registration.scss";
import Button from "../questionnaire/componenets/Button";
import LanguageChanger from "../questionnaire/componenets/LanguageChanger";
import Loading from "../components/Loading";

class Billing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guid: this.props.guid ? this.props.guid : this.props.match.params.guid,
      billing: null,
    };
  }

  componentDidMount() {
    axios
      .get(`/pay/${this.state.guid}`)
      .then((res) => this.setState({ billing: res.data }));
  }

  handleAgreeWithBillingClick() {
    axios
      .post(`/pay/${this.state.guid}/agree-with-billing`)
      .then(() => this.props.onContinue());
  }

  toConfirmationPage() {
    window.location.href = this.state.billing.confirmationPageUrl;
  }

  toWelcome() {
    window.location.href =
      window.location.origin +
      "/welcome/" +
      this.state.billing.dossierLookupGuid;
  }

  render() {
    return (
      <div className="questionnaire-root registration-container">
        <div>
          <LanguageChanger />
          <h1>{this.props.t("ipay-title")}</h1>

          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderContent() {
    if (this.state.billing === null) {
      return <Loading />;
    }

    if(this.state.billing.isAdhdSameDossierFlow) {
      if (this.state.billing.billingAgreedAt) {
        return this.renderAlreadyConfirmed();
      } else {
          return this.renderAdhdContractedHealthCare();
      }
    }
    else if (this.state.billing.isContractedHealthCare) {
      if (this.state.billing.billingAgreedAt) {
        return this.renderAlreadyConfirmed();
      } else {
          return this.renderContractedHealthCare();
      }
    } else if (this.state.billing.isPayedByThirdParty) {
      if (this.state.billing.billingAgreedAt) {
        return this.renderAlreadyConfirmed();
      } else {
        return this.renderThirdParty();
      }
    } else if (this.state.billing.isPayedByPaymentLink) {
      if (this.state.billing.billingAgreedAt) {
        return this.renderAlreadyConfirmed();
      } else {
        return this.renderUnverified();
      }
    } else {
      if (
        this.state.billing.subscriptionStatus ===
        subscriptionStatusEnum.UNVERIFIED
      ) {
        return this.renderUnverified();
      }

      if (
        this.state.billing.subscriptionStatus ===
          subscriptionStatusEnum.ACTIVE &&
        this.state.billing.billingAgreedAt
      ) {
        return this.renderPaymentIsSetup();
      }
    }

    return this.renderInvalidBillingStatus();
  }

  renderContractedHealthCare() {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t("ipay-contracted-care", {
              replace: { firstName: this.state.billing.firstName },
            }),
          }}
        />

        <Button onClick={() => this.handleAgreeWithBillingClick()}>
          {this.props.t("ipay-payment-by-health-insurer-button")}
        </Button>
      </div>
    );
  }

  renderAdhdContractedHealthCare() {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t("ipay-adhd-contracted-care", {
              replace: { firstName: this.state.billing.firstName },
            }),
          }}
        />

        <Button onClick={() => this.handleAgreeWithBillingClick()}>
          {this.props.t("ipay-payment-by-health-insurer-button")}
        </Button>
      </div>
    );
  }

  renderThirdParty() {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t("ipay-upw", {
              replace: { firstName: this.state.billing.firstName },
            }),
          }}
        />

        <Button onClick={() => this.handleAgreeWithBillingClick()}>
          {this.props.t("ipay-payment-by-third-party-button")}
        </Button>
      </div>
    );
  }

  renderPaymentIsSetup() {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t("ipay-finished", {
              firstName: this.state.billing.firstName,
            }),
          }}
        />

        <Button onClick={() => this.toWelcome()}>
          {this.props.t("continue")}
        </Button>
      </div>
    );
  }

  renderUnverified() {
    return (
      <div>
        {this.renderBillingMethod()}

        <div
          dangerouslySetInnerHTML={{
            __html: this.props.t("ipay-unverified-ending"),
          }}
        />

        <Button onClick={() => this.handleAgreeWithBillingClick()}>
          {this.props.t("ipay-button")}
        </Button>

        <div
          dangerouslySetInnerHTML={{ __html: this.props.t("ipay-footer") }}
        />
      </div>
    );
  }

  renderAlreadyConfirmed() {
    return <div>Billing already confirmed.</div>;
  }

  renderBillingMethod() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: this.props.t(this.getBillingMethod(), {
            replace: { firstName: this.state.billing.firstName },
          }),
        }}
      />
    );
  }

  getBillingMethod() {
    if (!this.state.billing.isInsuredHealthCare) {
      return "ipay-uninsured-care";
    }

    return "ipay-not-contracted-care";
  }

  renderInvalidBillingStatus() {
    return (
      <div>
        An error occured.
      </div>
    );
  }
}

export default withTranslation()(Billing);
