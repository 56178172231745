import axios from "axios";
import errorsStore from "./questionnaire/errorsStore";
import * as HttpStatus from "http-status-codes";

axios.defaults.baseURL =
  window.location.protocol + "//" + window.location.host + "/api/";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";
axios.defaults.withCredentials = true;

// todo? move this to index.js https://github.com/axios/axios/issues/2315
axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (error) => {
    if (!error.response) {
      errorsStore.addError("Er ging iets mis, probeer het opnieuw.");
      return Promise.reject(error);
    }

    switch (error.response.status) {
      case HttpStatus.INTERNAL_SERVER_ERROR:
        errorsStore.addError("Er ging iets mis, probeer het opnieuw.");
        break;
      case HttpStatus.UNAUTHORIZED:
      case HttpStatus.FORBIDDEN:
        errorsStore.addError("Geen toegang");
        break;
      case HttpStatus.REQUEST_TOO_LONG:
        errorsStore.addError(
          "Bestand is te groot, verklein het bestand en probeer opnieuw."
        );
        break;
      default:
        break;
    }

    return Promise.reject(error);
  }
);

export default axios;
